@use 'styles/_variables.scss' as *;
.text {
  font-family: $copy-font-family;
  line-height: 1.5;

  &.primary {
    color: $almost-black-60;
  }

  &.secondary {
    color: $almost-black;
  }
}

.inputContainer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: $global-margin * 1.5 0 $global-margin * 0.5;
  padding: 0 1px;
  width: 100%;

  @media (max-width: $s-screen) {
    align-items: center;
    flex-direction: column;
  }

  > div {
    margin: 0 $global-margin * 0.5;
    width: 30%;

    &:first-child {
      margin-left: 0;
      width: 40%;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $s-screen) {
      margin: $global-margin * 0.5 0;
      width: 70%;

      &:first-child {
        width: 70%;
      }
    }
  }
}
