@use 'styles/_variables.scss' as *;
.box {
  margin-top: $global-margin * 2;

  @media (max-width: $s-screen) {
    margin-top: $global-margin * 1.5;
  }
}

.preview {
  &__image {
    overflow: hidden;
  }

  &__modalImage {
    overflow: auto;
    max-height: 68vh;
  }
}
