@use 'styles/_variables.scss' as *;
.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-margin;

  &__info {
    color: $almost-black-60;
    margin-top: $global-margin;
    text-align: center;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
  }

  &__buttons {
    display: flex;

    .right {
      justify-content: flex-end;
    }
  }

  &__button {
    margin-right: $global-margin * 0.5;

    &:last-child {
      margin-right: 0;
    }
  }

  &__pageLabel {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border: 1px solid $pale-gray-6;
    border-radius: $button-border-radius;
    min-width: 6rem;
    padding: 0 0.5 * $global-padding;
    height: 2.5rem;
    margin: 0 $global-margin * 0.625;

    @media (max-width: $s-screen) {
      font-size: $base-font-size * 0.95;
    }

    @media (max-width: $xs-screen) {
      height: 1.75rem;
    }

    &.input {
      font-family: $brand-font-family;
      text-align: center;
      width: 6rem;

      &:focus {
        outline: none;
      }
    }
  }

  &Modal {
    margin-bottom: 0;
  }

  &Slider {
    margin: $global-margin * 0.5 0;
    padding: 0 $global-padding;

    input[type="range"] {
      position: relative;
      margin-bottom: 8px;

      &::before {
        content: "";
        position: absolute;
        height: 4px;
        top: -50%;
        transform: translateY(50%);
        background-color: $indigo;
        width: var(--slider-width);
        pointer-events: none;
      }

      &::-webkit-slider-runnable-track {
        height: 4px;
      }

      &::-moz-range-track {
        height: 4px;
      }

      &::-webkit-slider-thumb {
        height: 24px;
        width: 24px;
        margin-top: -10px;
      }

      &::-moz-range-thumb {
        height: 24px;
        width: 24px;
        margin-top: -10px;
      }
    }
  }
}
