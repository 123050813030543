@use 'styles/_variables.scss' as *;
.reviews {
  margin: $global-padding * 3 0;
  font-family: $copy-font-family;
  word-break: break-word;
  clear: right;

  &List {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $global-margin * 1.2;
    margin-bottom: $global-margin * 1.2;

    .header {
      margin-bottom: $global-margin * 0.5;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $global-margin;

    h2 {
      margin-bottom: $global-margin * 0.5;
    }

    &__rating {
      display: flex;
      align-items: center;

      &__count {
        padding-left: $global-padding * 0.5;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $global-margin * 1.2;
    margin-bottom: $global-margin * 1.2;

    &__header {
      margin-bottom: $global-margin * 0.5;
    }
  }

  .greyText {
    color: $almost-black-60;
  }

  &__item {
    background: $pale-gray-2;
    padding: $global-padding;
    margin-bottom: 0.3 * $global-margin;

    &__meta {
      display: flex;
      justify-content: space-between;
      font-size: $small-font-size;
      gap: $global-margin;
      line-height: 1.33;

      &.noRating {
        justify-content: flex-end;
      }

      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__body {
      padding-top: $global-padding;
      padding-bottom: $global-padding;
    }

    &__author {
      font-weight: bold;
    }

    &__helpful {
      display: flex;
      align-items: center;

      &__count {
        text-align: right;
        padding-right: $global-padding * 0.5;
      }

      &__button {
        min-width: 60px;
      }
    }

    &__report {
      font-size: $small-font-size;
      font-weight: 300;
      font-family: $brand-font-family;
    }

    &__edit_review {
      color: $indigo;
      padding: 0;
      text-align: left;
    }
  }

  &__noReviews {
    display: flex;
    justify-content: center;
  }

  &__modal {
    &__form {
      display: flex;
      flex-direction: column;

      &__rating {
        .starRating {
          padding-top: $global-padding * 0.5;
        }

        margin-bottom: $global-margin * 1.5;
      }

      &__reviewBody {
        label {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          padding-bottom: $global-padding * 0.3;
        }

        display: flex;
        flex-direction: column;
      }
    }

    &__error {
      margin: $global-margin;
    }
  }
}

.centered_button {
  display: flex;
  justify-content: center;
}
