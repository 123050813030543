@use 'styles/_variables.scss' as *;
.productPage {
  &__intro {
    display: flex;
    align-items: flex-start;
    font-family: $copy-font-family;
    margin-top: $global-margin * 3;

    &__image {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-basis: 28%;
      padding: 0 $global-padding * 4.125;

      @media (max-width: $l-screen) {
        flex-basis: 35%;
      }

      @media (max-width: $m-big-screen) {
        padding-left: 0;
      }

      @media (max-width: $m-screen) {
        flex-basis: 40%;
        padding-right: $global-padding;
      }

      button {
        margin-top: $global-margin;
      }
    }

    &__details {
      flex: 1;
      overflow: hidden;
      overflow-wrap: break-word;

      h1 {
        margin: 0 0 $global-margin * 0.5;
      }

      &__rating {
        display: flex;
        margin-top: $global-margin;
        align-items: center;

        &__count {
          padding-left: $global-padding * 0.5;
        }
      }

      &__addToCart {
        display: flex;
        margin-top: $global-margin * 1.5;

        .bookFormatSelection {
          margin-right: $global-margin * 0.5;
        }
      }

      &__share {
        display: flex;
        align-items: center;
        margin-top: $global-margin * 1.5;

        span {
          font-size: $small-font-size;
          color: $almost-black-60;
        }

        a:hover {
          text-decoration: none;
        }

        i {
          color: $indigo;
          font-size: 2 * $base-font-size;
          padding: 0.5 * $global-padding;

          @media (min-width: $m-screen) {
            font-size: 1.5 * $base-font-size;
            margin-left: $global-margin * 0.5;
            padding: 0;
          }
        }
      }

      &__shipping {
        display: flex;
        align-items: center;
        margin-top: $global-margin;

        i {
          color: $indigo;
          font-size: 1.5rem;
          margin-right: $global-margin * 0.5;
        }

        span {
          line-height: 1.67;
          font-size: $small-font-size;
          color: $almost-black-60;

          a {
            display: block;
            margin-bottom: 3px;
          }
        }
      }

      &__explicit {
        color: $almost-black-60;
        margin-top: $global-margin * 0.5;
        display: flex;
        align-items: center;
        font-size: $small-font-size;

        i {
          font-size: 1.5rem;
          margin-right: $global-margin * 0.5;
        }
      }
    }
  }
}

.productAuthor {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: $almost-black-60;
  margin: 0;

  @media (max-width: $s-screen) {
    font-size: $small-font-size;
  }

  i {
    margin-right: $global-margin * 0.5;
  }

  a {
    margin-left: $global-margin * 0.5;
  }
}
