@use 'styles/_variables.scss' as *;
.productThumbnailContainer {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;
  box-shadow: 8px 10px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 8px 10px 16px 0 rgba(0, 0, 0, 0.35);
  }

  &__clickable {
    &:hover {
      cursor: pointer;
    }
  }

  &__printedBook {
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0) 10%,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.2) 80%,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
      opacity: 0.25;
      border-right: 1px solid $dark-gray-2;
    }
  }

  .noCover {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $base-font-size * 0.8;
    font-weight: 700;
    line-height: $base-font-size * 1.25;
    text-transform: uppercase;
    color: $pale-gray-6;
    height: 15rem;
    width: 100%;
    border: 1px solid $pale-gray-4;
    padding-left: 11px;

    @media (max-width: $m-screen) {
      font-size: $base-font-size * 0.6;
      height: 8rem;
    }

    @media (max-width: $xs-screen) {
      font-size: $base-font-size * 0.5;
      height: 6rem;
    }
  }

  .coverImage,
  .calendarOverlay {
    width: 100%;
  }

  .calendarOverlay {
    position: absolute;
  }
}
