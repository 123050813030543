@use 'styles/_variables.scss' as *;
.container {
  background: $pale-gray-2;
}

.authorSpotlightInfo {
  margin: $global-padding * 3 0;
  color: $almost-black-70;
  font-family: $copy-font-family;
  line-height: 1.5;
  word-break: break-word;
}

.infoHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $global-margin;
}

.imageLink {
  padding-right: $global-padding * 2;
}

.about {
  margin-bottom: $global-padding;
}

.authorProfileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  @media (max-width: $m-big-screen) {
    width: 4rem;
    height: 4rem;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
