@use 'styles/_variables.scss' as *;
.descriptionContainer {
  margin-top: $global-margin * 3.5;
  color: $almost-black-70;
  font-family: $copy-font-family;
  line-height: 1.5;
  word-break: break-word;
  white-space: break-spaces;
}

.specList {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-family: $copy-font-family;
  font-size: $small-font-size;
  line-height: 1.33;

  dt {
    flex: 0 0 30%;
    text-overflow: ellipsis;
    color: $almost-black-60;
    margin-bottom: $global-margin * 0.5;

    @media (max-width: $s-screen) {
      flex: 0 0 60%;
    }
  }

  dd {
    flex: 0 0 70%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;

    @media (max-width: $s-screen) {
      flex: 0 0 40%;
    }
  }
}

.keywords {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  color: $indigo;
  margin-bottom: $global-margin * 5;
  gap: $global-margin 1.5 * $global-margin;

  a {
    line-height: 125%;
  }
}

.reportContent {
  margin-bottom: $global-margin * 3;
  float: right;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
