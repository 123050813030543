@use 'styles/_variables.scss' as *;
.reviewModal {
  padding: $global-padding;

  &__product {
    display: flex;
    margin-bottom: $global-margin * 3;

    &__image {
      display: flex;
      flex-basis: 25%;
      height: 100%;
    }

    &__description {
      margin: 0;
      max-height: 310px;
      color: $almost-black-70;
      display: flex;
      flex-basis: 75%;
      padding-left: $global-padding * 2;
      overflow-y: auto;
      line-height: 24px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__rating {
      .star-rating {
        padding-top: $global-padding * 0.5;
      }
      margin-bottom: $global-margin * 1.5;
    }

    &__review-body {
      label {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-bottom: $global-padding * 0.3;
      }
      display: flex;
      flex-direction: column;
    }
  }

  &__error {
    margin: $global-margin;
  }
}
